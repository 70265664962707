import { Header } from 'header'
import { Home as Layout, Seo } from 'ui-components'

interface HomeProps {
  seoProps?: React.ComponentProps<typeof Seo>
}

const seo = {
  title: 'Fast Address and Location Interactive﻿ Mapping Tool | Mapstack.io',
  description:
    'Rapidly turn your business address data into valuable AI powered growth insights. Find your next opportunity without manual analysis and spreadsheets.'
}

export const Home = (props: HomeProps) => {
  return (
    <>
      <Seo
        title={seo.title}
        description={seo.description}
        openGraph={{
          title: seo.title,
          description: seo.description,
          images: [
            {
              url: 'https://mapstack.io/images/og_image.webp',
              secureUrl: 'https://mapstack.io/images/og_image.webp',
              alt: 'Mapstack - Visualize and Map Location Data Instantly',
              type: 'image/webp',
              width: 1200,
              height: 630
            }
          ],
          url: 'https://mapstack.io/',
          siteName: 'Mapstack'
        }}
        {...props?.seoProps}
      />

      <Layout
        slots={{
          Header
        }}
      />
    </>
  )
}
