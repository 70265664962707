import { useEffect } from 'react'
import { homePageView } from 'tag-manager/page_view'
import { Home as Page } from 'web-pages/pages/Home'
import { getServerSideProps } from 'web-pages/pages/Home/getServerSideProps'

const Home = (props) => {
  useEffect(() => {
    homePageView()
  }, [])

  return <Page />
}

export { getServerSideProps }
export default Home
